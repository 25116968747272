import React from "react";
import ReactDOM from "react-dom/client";

import "bootstrap/dist/css/bootstrap.min.css";

import "./index.css";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// -- CUSTOM --
// If user is using as standalone pwa, disabling pinch-zoom so the pwa mimics an installed application.

window
  .matchMedia("(display-mode: standalone)")
  .addEventListener("change", (evt) => {
    console.log("display mode changed");
    if (evt.matches) {
      console.log("evt matches 'standalone'");
      document
        .getElementById("meta-viewport")
        .setAttribute(
          "content",
          "width=device-width, initial-scale=1, viewport-fit=cover, user-scalable=no,"
        );
    }
  });
